// React
import { ReactElement } from 'react';
// Reach
import { DialogContent, DialogOverlay } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
// Design System
import { Icon } from '@utmb/design-system/icons/Icon';
import { Font } from '@utmb/design-system/text/font';
// Features
import { PlaygroundIcon } from '@utmb/features/races/races-list/playgrounds/PlaygroundIcon';
// Types
import { IconsEnum } from '@utmb/types/icons';
import { PlaygroundEnum } from '@utmb/types/enum/PlaygroundEnum';
import { ColorTypesEnum } from '@utmb/types/colors';
// Utils
import { useTranslation } from 'next-i18next';
import { getColorStyle } from '@utmb/utils/colors';
// Styles
import '@reach/dialog/styles.css';
import styles from './playgrounds-modal.module.scss';

interface PlaygroundsModalProps {
    playgrounds: PlaygroundEnum[];
    isOpen?: boolean;
    onDismiss: (isOpen: boolean) => void;
}

export const PlaygroundsModal = (props: PlaygroundsModalProps): ReactElement | null => {
    const { playgrounds, isOpen = false, onDismiss } = props;

    const { t } = useTranslation();

    if (playgrounds.length === 0) {
        return null;
    }

    return (
        <DialogOverlay className={styles.playground_modal} isOpen={isOpen} onDismiss={() => onDismiss(false)}>
            <VisuallyHidden>Close</VisuallyHidden>

            <DialogContent style={{ boxShadow: '0px 10px 20px hsla(0, 0%, 0%, 0.13)' }} aria-label="Playground of the race">
                <Font.FuturaBook mobile="14" className={styles.title}>
                    {t('races.playground')}
                </Font.FuturaBook>
                <div className="container">
                    <div className="row">
                        {playgrounds.map((playground) => (
                            <div key={playground.valueOf()} className={`${styles.list} mt-3 col-6`}>
                                <div className={styles.icon}>
                                    <PlaygroundIcon color={getColorStyle(ColorTypesEnum.WS_PRIMARY)} icon={playground} />
                                </div>
                                <Font.OxaniumBold mobile="14" color={getColorStyle(ColorTypesEnum.WS_PRIMARY)} className={styles.title}>
                                    {t(`races.playgroundType.${playground}`)}
                                </Font.OxaniumBold>
                            </div>
                        ))}
                    </div>
                </div>
                <button className={styles.button_close} onClick={() => onDismiss(false)}>
                    <Icon variant={IconsEnum.CLOSE} clss={'color-secondary'} />
                </button>
            </DialogContent>
        </DialogOverlay>
    );
};
