import * as React from 'react';
import { FC } from 'react';
import { PlaygroundEnum } from '@utmb/types/enum/PlaygroundEnum';
import dynamic from 'next/dynamic';

export interface PlaygroundIconProps {
    icon: PlaygroundEnum;
    color: string;
}

export interface PlaygroundSvgIconProps {
    color: string;
}

const Icons = {
    [PlaygroundEnum.BEACH]: dynamic(() => import('./icons/BeachIcon')),
    [PlaygroundEnum.COASTAL_PATH]: dynamic(() => import('./icons/CoastalPathIcon')),
    [PlaygroundEnum.DAY_TIME]: dynamic(() => import('./icons/DayTime')),
    [PlaygroundEnum.DESERT]: dynamic(() => import('./icons/DesertIcon')),
    [PlaygroundEnum.GLACIER]: dynamic(() => import('./icons/GlacierIcon')),
    [PlaygroundEnum.HIGH_ALTITUDE]: dynamic(() => import('./icons/HighAltitudeIcon')),
    [PlaygroundEnum.HIKING_TRAIL]: dynamic(() => import('./icons/HikingTrailIcon')),
    [PlaygroundEnum.HILL]: dynamic(() => import('./icons/HillIcon')),
    [PlaygroundEnum.JUNGLE]: dynamic(() => import('./icons/JungleIcon')),
    [PlaygroundEnum.MOUNTAIN]: dynamic(() => import('./icons/MountainIcon')),
    [PlaygroundEnum.NIGHT_TIME]: dynamic(() => import('./icons/NightTimeIcon')),
    [PlaygroundEnum.ROAD]: dynamic(() => import('./icons/RoadIcon')),
    [PlaygroundEnum.SINGLE_TRACK]: dynamic(() => import('./icons/SingleTrackIcon')),
    [PlaygroundEnum.WOODS]: dynamic(() => import('./icons/WoodIcon')),
};

export const PlaygroundIcon: FC<PlaygroundIconProps> = ({ color, icon }) => {
    if (typeof Icons[icon] !== 'undefined') {
        const Icon = Icons[icon];
        return <Icon color={color} />;
    }
    return <></>;
};
