import { ReactElement } from 'react';
// Modules
import classNames from 'classnames/bind';
// Styles
import styles from './flag.module.scss';
import 'flag-icons/css/flag-icons.min.css';

const cx = classNames.bind(styles);

interface FlagProps {
    className?: string;
    isoCode: string;
    hideShadow?: boolean;
}

export const Flag = (props: FlagProps): ReactElement | null => {
    const { className, isoCode, hideShadow = false } = props;

    if (!isoCode?.length) {
        return null;
    }

    const classNameCSS = cx(className, 'fi', `fi-${isoCode.toLowerCase()}`, styles.flag, {
        hide_shadow: hideShadow,
    });

    return <span className={classNameCSS} />;
};
