import { ReactElement, useState } from 'react';
// Next
import { useTranslation } from 'next-i18next';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Flag } from '@utmb/design-system/flag/Flag';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { PlaygroundsModal } from '@utmb/design-system/playgrounds/modal/PlaygroundsModal';
// Features
import { getRaceCategoryIcon } from '@utmb/utils/race-categories-icons';
// Types
import EventView from '@utmb/types/view/EventView';
import { CloudinaryTransformEnum } from '@utmb/types/cloudinary-transform.enum';
import { IconsEnum } from '@utmb/types/icons';
// Utils
import { pIdToCloudinary } from '@utmb/utils/cloudinary';
import { toBase64, shimmer } from '@utmb/utils/miscellaneous';
import { ColorTypesEnum } from '@utmb/types/colors';
import { getColorStyle } from '@utmb/utils/colors';
// Styles
import styles from './event-card.module.scss';

const cx = classNames.bind(styles);

interface EventCardProps {
    className?: string;
    event: EventView;
    buttonColor?: string;
    buttonBgColor?: string;
    isCompactMode?: boolean;
    backButtonCallback?: (event: any) => void;
}

export const EventCard = (props: EventCardProps): ReactElement => {
    const {
        className,
        event,
        buttonColor = getColorStyle(ColorTypesEnum.WS_SECONDARY),
        buttonBgColor = getColorStyle(ColorTypesEnum.WS_PRIMARY),
        isCompactMode = true,
        backButtonCallback,
    } = props;

    const { t } = useTranslation();

    const [showDialog, setShowDialog] = useState(false);

    const eventCardCSS = cx(styles.event_card, className);
    const headerCSS = cx(styles.header, { is_compact: isCompactMode });
    const listCSS = cx(styles.list, 'futura-book', { is_compact: isCompactMode });

    /*const eventTypeOnWS = () => {
        if (event.isFinal) return 'UTMB World Series Final';
        if (event.isMajor) return 'UTMB World Series Major';
        return false;
    };*/

    return (
        <div className={eventCardCSS}>
            {!!event.playgrounds && <PlaygroundsModal playgrounds={event.playgrounds} isOpen={showDialog} onDismiss={setShowDialog} />}
            <div className={headerCSS} style={{ backgroundColor: event.primaryColor || getColorStyle(ColorTypesEnum.DEFAULT) }}>
                {event.picture && (
                    <div className={styles.image_container}>
                        <Image objectFit="cover" image={pIdToCloudinary(event.picture, event.title)} maxWidth={500} />
                    </div>
                )}
                {!isCompactMode && (
                    <button onClick={backButtonCallback} className={styles.back_button}>
                        <Icon variant={IconsEnum.LEFT} />
                    </button>
                )}
                {event.logo && (
                    <div className={styles.logo}>
                        <div className={styles.logo_wrapper}>
                            <Image
                                objectFit="contain"
                                image={pIdToCloudinary(event.logo, event.title)}
                                addOptions={[CloudinaryTransformEnum.TRIM]}
                                maxWidth={250}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.content}>
                {/* <Font.FuturaHeavy desktop="24" className={styles.event_name}>
                    {event.title}
                    {eventTypeOnWS() && (
                        <Font.FuturaLight desktop="16" className={styles.eventTypeOnWS}>
                            {eventTypeOnWS()}
                        </Font.FuturaLight>
                    )}
                </Font.FuturaHeavy>

                <div className={styles.playgrounds}>
                    {event.playgrounds.slice(0, 3).map((playground, index: number) => (
                        <PlaygroundIcon key={index} icon={playground} color="#000d44" />
                    ))}
                    {event.playgrounds.length > 3 && (
                        <button className={styles.playgrounds_button} onClick={() => setShowDialog(true)}>
                            <Font.OxaniumBold className={styles.playgrounds_number}>+{event.playgrounds.length - 3}</Font.OxaniumBold>
                        </button>
                    )}
                </div> */}

                <ul className={listCSS}>
                    <li>
                        <div className={styles.prefix}>
                            <Flag isoCode={event.countryCode} />
                        </div>
                        <div>
                            <span className={styles.placeName}>{event.placeName}</span>,&nbsp;<span className={styles.countryName}>{event.country}</span>
                        </div>
                    </li>
                    <li>
                        <div className={styles.prefix}>
                            <Icon variant={IconsEnum.CALENDAR} />
                        </div>
                        {event.dateFormated}
                    </li>
                    {!!event.raceCategories.length && (
                        <li className={styles.categories_list_container}>
                            <div className={styles.prefix}>
                                <Icon variant={IconsEnum.RACE} />
                            </div>
                            {event.raceCategories.map((category, index: number) => (
                                <div key={index} className={styles.category_icon}>
                                    <Image
                                        placeholder="blur"
                                        blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(150, 50))}`}
                                        layout="fill"
                                        objectFit="contain"
                                        image={getRaceCategoryIcon(category)}
                                    />
                                </div>
                            ))}
                        </li>
                    )}
                    {event.isMajor && (
                        <li>
                            <div className={styles.prefix}>
                                <Icon variant={IconsEnum.RUNNING_STONES} />
                            </div>
                            {t('eventCard.doubleRunningStones')}
                        </li>
                    )}
                    <li>
                        <div className={styles.prefix}>
                            <span className={`${styles.registration_status} ${event.status.open ? styles.open : styles.close}`}></span>
                        </div>
                        {t(`racesList.${event.status.status}`)}
                    </li>
                </ul>

                {event.url && (
                    <Link.Button
                        className={styles.button}
                        label={t('breadcrumbs.discover')}
                        href={event.url}
                        color={buttonColor}
                        bgcolor={buttonBgColor}
                    ></Link.Button>
                )}
            </div>
        </div>
    );
};
