export enum PlaygroundEnum {
    BEACH = 'beach',
    COASTAL_PATH = 'coastalPath',
    DAY_TIME = 'dayTime',
    DESERT = 'desert',
    GLACIER = 'glacier',
    HIGH_ALTITUDE = 'highAltitude',
    HIKING_TRAIL = 'hikingTrail',
    HILL = 'hill',
    JUNGLE = 'jungle',
    MOUNTAIN = 'mountain',
    NIGHT_TIME = 'nightTime',
    ROAD = 'road',
    SINGLE_TRACK = 'singleTrack',
    WOODS = 'woods',
}
